.previewImgBox {
  height: 38px;
  position: relative;
}
.previewImgBox img {
  height: 100%;
  object-fit: cover;
  width: 100%;
  border-radius: 4px;
}
.previewImgBox svg {
  font-size: 16px;
  position: absolute;
  right: 2px;
  top: 2px;
  cursor: pointer;
}

.Mui-selected {
  background: #7258db !important;
  color: #ffffff !important;
  border-radius: 10px !important;
}

.css-1aquho2-MuiTabs-indicator {
  display: none !important;
}
span.MuiTabs-indicator.css-ttwr4n {
  display: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.modalss {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  margin: 0;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.modal-contentss {
  position: relative;
  background: white;
  padding: 6px;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transform: scale(0.9);
  transition: transform 0.3s ease;
}

.modal-contentss img {
  max-width: 640px;
  max-height: 450px;
}

.modalss.show {
  opacity: 1;
  visibility: visible;
}

.modal-contentss.show {
  transform: scale(1);
}

.close-btnss {
  position: absolute;
  top: -10px;
  right: -10px;
  background: red;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  width: 30px;
  height: 30px;
}

.modal-overlayss {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease;
  margin: 0;
}

.modal-overlayss.show {
  opacity: 1;
  visibility: visible;
} 