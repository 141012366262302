.stepperTitle_Box h5 {
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  line-height: 20px;
  margin-bottom: 2px;
  margin-top: 0px;
}
/* h6 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0px;
  line-height: 20px;
  color: #333333;
  margin-top: 0;
} */
