.toggle {
    position: relative;
    display: inline-block;
}

.toggle__input {
    display: none;

    &:checked {
        &+.toggle__label {
            background: #0BCE59;

            &::after {
                left: 19px;
            }
        }
    }
}

.toggle__label {
    display: block;
    width: 36px;
    height: 22px;
    background-color:#cfcfcf;
    border-radius: 99px;
    cursor: pointer;
    transition: background-color 0.6s ease;
    margin-bottom: 0px;

    &::after {
        content: '';
        position: absolute;
        top: 4px;
        left: 3px;
        width: 14px;
        height: 14px;
        background-color: #ffffff;
        border-radius: 50%;
        transition: left 0.4s ease;
    }
}